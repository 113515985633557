import { useContext, useEffect, useState } from "react";
import Spinner from "@edenred/spinner";
import Page from "../components/Page";
import ErrorPage from "../components/ErrorPage";
import { MicroCopyContext } from "@edenred/micro-copy";
import { Typography } from "@mui/material/";
import { graphql, PageProps } from "gatsby";
import Button from "../components/Button";
import { useLocation } from "@reach/router";
import Redirect from "../components/Redirect";

type Props = PageProps<Pick<GatsbyTypes.Query, "contentfulSite">>;

const NotFound = ({
  genericClient
}: {
  genericClient?: GatsbyTypes.ContentfulClient;
}) => {
  const getMicroCopy = useContext(MicroCopyContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  if (isLoading)
    return (
      <Page title={getMicroCopy("general.loading")}>
        <Spinner fullPage aria-label={getMicroCopy("general.loading")}>
          {getMicroCopy("general.loading")}
        </Spinner>
      </Page>
    );

  return (
    <ErrorPage
      homePath={genericClient?.path || "/"}
      client={genericClient}
      title={getMicroCopy("error.not-found.title")}
      description={getMicroCopy("error.not-found.description")}
    >
      <Typography variant="h4" component="h1" sx={{ margin: 3 }}>
        {getMicroCopy("error.not-found.title")}
      </Typography>
      <Button href={genericClient?.path || "/"} size="large">
        {getMicroCopy("general.back")}
      </Button>
    </ErrorPage>
  );
};

export default function NotFoundPage({ data }: Props) {
  const [isMounted, setIsMounted] = useState(false);
  const location = useLocation();
  const genericClient = data.contentfulSite?.genericClient;

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) return null;

  const { pathname, search } = location;

  if (/[A-Z]/.test(pathname))
    return <Redirect pathname={pathname.toLowerCase()} search={search} />;

  return <NotFound genericClient={genericClient} />;
}

export const pageQuery = graphql`
  query NotFoundPageQuery {
    contentfulSite {
      genericClient {
        ...Client
      }
    }
  }
`;
