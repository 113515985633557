import { MicroCopyContext } from "@edenred/micro-copy";
import Spinner from "@edenred/spinner";
import { useContext, useEffect } from "react";
import Page from "./Page";
import { navigate } from "@reach/router";

type Props = {
  pathname: string;
  search?: string;
};

export default function Redirect({ pathname, search }: Props) {
  const getMicroCopy = useContext(MicroCopyContext);
  useEffect(() => {
    navigate(pathname + (search ? search : ""), { replace: true });
  }, []);
  return (
    <Page title={getMicroCopy("general.loading")}>
      <Spinner fullPage aria-label={getMicroCopy("general.loading")}>
        {getMicroCopy("general.loading")}
      </Spinner>
    </Page>
  );
}
