import { ErrorOutline as ErrorIcon } from "@mui/icons-material";
import { Box } from "@mui/material/";
import { ReactNode } from "react";
import { PageWithHeaderAndFooter } from "../components/Page";

type Props = {
  description: string;
  title: string;
  children?: ReactNode;
  homePath: string;
  client?: GatsbyTypes.ContentfulClient;
};

export default function ErrorPage({
  description,
  title,
  children,
  homePath,
  client
}: Props) {
  return (
    <PageWithHeaderAndFooter
      title={title}
      description={description}
      client={client}
      homePath={homePath}
    >
      <Box
        sx={{
          textAlign: "center",
          my: 10,
          mx: "auto",
          maxWidth: 592
        }}
      >
        <ErrorIcon
          sx={{
            backgroundColor: "grey.200",
            borderRadius: 50,
            color: "grey.600",
            fontSize: 80,
            p: 1.5
          }}
        />
        {children}
      </Box>
    </PageWithHeaderAndFooter>
  );
}
